import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../css/main.css';
import '../css/icons.css';
import linkImage from "../gfx/link.png";
import DeleteModal from '../components/deleteModal';
import Navbar from '../components/navbar';
import ClipboardButton from '../components/clipboardButton';
import Token from '../components/token';
const settings = require("../settings.json");
const serverName = settings.serverData.name;
const shortLink = settings.serverData.shortLink;

class LinksPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            list: [],
            convertData: {
                link: "",
                src: ""
            },
            disabledForm: false,
            delTarget: 999
        }
    }

    componentDidMount = async () => {
        await this.refreshData();
        console.log(settings);
    }

    refreshData = async () => {
        var data = await fetch(`${serverName}/get`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Token.value
            },
            body: JSON.stringify({
                table: "links"
            })
        })
        data = await data.json();
        console.log(data);
        await this.setState({list: data})
    }

    sendData = async () => {
        await this.setState({disabledForm: true});
        var res = await fetch(`${serverName}/add`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Token.value
            },
            body: JSON.stringify({
                link: this.state.convertData.link,
                src: this.state.convertData.src
            })
        })
        res = await res.text();
        console.log(res);
        await this.setState({convertData: {link: "", src: ""}})
        await this.refreshData();
        await this.setState({disabledForm: false});
    }

    deleteItem = async () => {
        await this.setState({disabledForm: true});
        var res = await fetch(`${serverName}/del`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Token.value
            },
            body: JSON.stringify({
                table: "links",
                id: this.state.delTarget
            })
        })
        res = await res.text();
        console.log(res);
        await this.refreshData();
        await this.setState({disabledForm: false});
    }

    render() {

        var ListItems = this.state.list.map((x,y) => {
            return <li key={y} className="list-group-item" style={{display: "flex", justifyContent: "space-between", borderWidth: "1.5px"}}><span style={{wordBreak: "break-all"}}>{x.src}</span><div style={{width: "65px", marginLeft: "10px"}}><span style={{display: "flex", justifyContent: "space-evenly", fontSize: "calc(var(--bs-body-font-size) * 1.2)"}}><ClipboardButton text={window.location.origin+ "/link/" +x.link}/><i
            className="bi bi-trash-fill" data-bs-toggle="modal" onClick={() => {this.setState({delTarget: x.id})}} data-bs-target="#exampleModal"></i></span></div></li>
        })

        return (
            <div>

                <DeleteModal onDelete={this.deleteItem}/>
                <Navbar title="Link shortener" back={true}/>

                <div className="container" style={{ paddingTop: "70px" }}>
                    <div className="row">
                        <div className="gy-4 col-md-6">
                            <div className="card bg-light text-dark">
                                <img className="card-img" src={linkImage} alt="Link" />
                                <div className="card-img-overlay"
                                    style={{ display: "flex", textAlign: "center", justifyContent: "center", alignItems: "center" }}>
                                    <div style={{ width: "80%", backgroundColor: "#f7f9fb", padding: "5px" }}>
                                        Convert from:
                                        <input type="text" disabled={this.state.disabledForm} value={this.state.convertData.src} onChange={(ev) => {this.setState({convertData: {link: this.state.convertData.link, src: ev.target.value}})}} style={{ width: "100%" }} /><br />
                                        To:
                                        <input type="text" disabled={this.state.disabledForm} value={this.state.convertData.link} onChange={(ev) => {this.setState({convertData: {src: this.state.convertData.src, link: ev.target.value}})}} style={{ width: "100%" }} /><br />
                                        <button onClick={this.sendData} disabled={this.state.disabledForm} style={{ marginTop: "10px" }}>Convert</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="gy-4 col-md-6">
                            <ul className="list-group">
                                {ListItems}
                            </ul>
                        </div>
                    </div>
                </div>
                {/* <script type="text/javascript" src={"../js/filedrop-min.js"}></script> */}
            </div>
        );
    }
}

export default LinksPage;