import React from "react";
import { Navigate } from "react-router-dom";
import Loader from "../components/loader";
import Token from "../components/token";

const settings = require("../settings.json");
const serverName = settings.serverData.name;

class Authorization extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            res: undefined
        }
    }

    async componentDidMount() {
        var x = await fetch(`${serverName}/auth`, {
            method: "POST",
            headers: {
                'Authorization': Token.value
            }
        })
        x = await x.json();
        this.setState({ res: x.res })
    }

    render() {
        const x = this.state.res;
        if (this.props.loginPage === true) {
            if (x === false) { return this.props.path }
            else if (x === undefined) { return <Loader /> }
            else { return <Navigate to="/boxes" /> }
        } else {
            if (x === false) { return <Navigate to="/" /> }
            else if (x === undefined) { return <Loader /> }
            else { return this.props.path }
        }

    }

}

export default Authorization