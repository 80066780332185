import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import uploadImage from "../gfx/upload.png";
import DeleteModal from '../components/deleteModal';
import Navbar from '../components/navbar';
import ClipboardButton from '../components/clipboardButton';
import { FileDrop } from 'react-file-drop';
import "../css/main.css";
import "../css/filedrop.css"
import Token from '../components/token';
const settings = require("../settings.json");
const serverName = settings.serverData.name;
const fileLink = settings.serverData.fileLink;

class FilesPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            list: [],
            convertData: {
                link: "",
                src: undefined
            },
            disabledForm: false,
            delTarget: 999,
            fileTransfered: false
        }
    }

    componentDidMount = async () => {
        await this.refreshData();
    }

    refreshData = async () => {
        var data = await fetch(`${serverName}/get`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Token.value
            },
            body: JSON.stringify({
                table: "files",
            })
        })
        data = await data.json();
        console.log(data);
        await this.setState({ list: data })
    }

    deleteItem = async () => {
        await this.setState({ disabledForm: true });
        var res = await fetch(`${serverName}/del`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': Token.value
            },
            body: JSON.stringify({
                table: "files",
                id: this.state.delTarget
            })
        })
        res = await res.text();
        console.log(res);
        await this.refreshData();
        await this.setState({ disabledForm: false });
    }

    transferFile = (x) => {
        console.log(x);
        this.setState({convertData: {link: "", file: x[0]}})
        this.setState({fileTransfered: true});
    }

    sendData = async () => {
        var form = new FormData();
        form.append("file", this.state.convertData.file)
        form.append("link", this.state.convertData.link)
        console.log(form.get("file"));
        await this.setState({disabledForm: true});
        var res = await fetch(`${serverName}/addFile`, {
            method: "POST",
            headers: {
                'Authorization': Token.value
            },
            body: form
        })
        res = await res.text();
        console.log(res);
        await this.setState({convertData: {link: "", src: ""}})
        await this.refreshData();
        await this.setState({disabledForm: false});
        await this.setState({fileTransfered: false});

    }

    render() {

        var ListItems = this.state.list.map((x, y) => {
            return <li key={y} className="list-group-item" style={{ display: "flex", justifyContent: "space-between", borderWidth: "1.5px" }}><span style={{wordBreak: "break-all"}}>{x.src}</span><div style={{width: "65px", marginLeft: "10px"}}><span style={{display: "flex", justifyContent: "space-evenly", fontSize: "calc(var(--bs-body-font-size) * 1.2)" }}><ClipboardButton text={window.location.origin+ "/file/" + x.link}/><i
                className="bi bi-trash-fill" data-bs-toggle="modal" onClick={() => { this.setState({ delTarget: x.id }) }} data-bs-target="#exampleModal"></i></span></div></li>
        })

        return (
            <main>
                <DeleteModal onDelete={this.deleteItem} />
                <Navbar title="File manager" back={true} />

                <div className="container" style={{ paddingTop: "70px" }}>
                    <div className="row">
                        <div className="gy-4 col-md-6">
                            <div className="card bg-light text-dark">
                                <img className="card-img" src={uploadImage} alt="Upload" />

                                <div className="card-img-overlay"
                                    style={{ display: this.state.fileTransfered?"flex":"none", textAlign: "center", justifyContent: "center", alignItems: "center"}}>
                                    <div style={{ width: "80%", backgroundColor: "#f7f9fb", padding: "5px" }}>
                                        Link:
                                        <input type="text" disabled={this.state.disabledForm} value={this.state.convertData.link} onChange={(ev) => { this.setState({ convertData: { link: ev.target.value, file: this.state.convertData.file } }) }} style={{ width: "100%" }} /><br />
                                        <button onClick={this.sendData} disabled={this.state.disabledForm} style={{ marginTop: "10px" }}>Convert</button>
                                    </div>
                                </div>


                                <FileDrop onDrop={this.transferFile}>
                                    <div className="card-img-overlay"
                                        style={{ display: this.state.fileTransfered?"none":"flex", textAlign: "center", justifyContent: "center", alignItems: "center"}}>

                                        <fieldset id="zone" style={{ width: "fit-content", backgroundColor: "#f7f9fb" }}>

                                            <div className="no-file">
                                                <legend>Drop a file inside&hellip;</legend>
                                                <input onChange={(ev) => {this.transferFile(ev.target.files); ev.target.value=null }} style={{ textAlignLast: "center" }} type="file"></input><p>Or click here to <em>Browse</em>..</p>
                                            </div>

                                            <div className="file-hold">
                                                <legend>Drop here!</legend>
                                            </div>

                                            <div className="file-over">
                                                <legend>Drop now!</legend>
                                            </div>

                                            <p style={{ zIndex: 10, position: "relative" }}>
                                            </p>

                                        </fieldset>
                                    </div>
                                </FileDrop>
                            </div>
                        </div>
                        <div className="gy-4 col-md-6">
                            <ul className="list-group">
                                {ListItems}
                            </ul>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export default FilesPage;