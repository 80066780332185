import React, { Component } from 'react';
import ClipboardJS from "clipboard";
import { Tooltip } from "bootstrap";

class ClipboardButton extends Component {
    
    copy = (e) => {
        var x = e.target;
        x.classList.add("bi-clipboard-check");
        x.classList.remove("bi-clipboard");
        x.setAttribute("title", "Copied!")
    }

    copyOut = (e) => {
        var x = e.target;
        setTimeout(() => {
            x.classList.add("bi-clipboard");
            x.classList.remove("bi-clipboard-check");
            x.setAttribute("title", "Copy link")
        }, 300)
    }

    componentDidMount = () => {
        new ClipboardJS('.copy');
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl)
        })
    }

    render() {
        return (
            <i className="copy bi bi-clipboard" onClick={this.copy}
                onMouseLeave={this.copyOut} data-toggle="tooltip" data-bs-placement="bottom" title="Copy link"
                data-clipboard-text={this.props.text} style={this.props.styleProp}></i>
        );
    }
}

export default ClipboardButton;