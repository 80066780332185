import React, { Component } from 'react';
import Token from './token';

class Navbar extends Component {

    logout = (ev) => {
        ev.preventDefault();
        Token.remove();
        window.open("/", "_self");
    }

    backButton = (back) => {
        if (back.back) {
            return <a className="navbar-brand backButton" href="./boxes">&lt;</a>
        } else {
            return null
        }
    }

    render() {
        return (
            <nav className="navbar fixed-top navbar-light bg-light" style={{ textAlign: "right" }}>
                <div className="container-fluid justify-content-between">
                    <div>
                        <this.backButton back={this.props.back} />
                        <span className="navbar-brand">{this.props.title}</span>
                    </div>
                    <a className="navbar-brand" onClick={this.logout} href="/">Logout</a>
                </div>
            </nav>
        );
    }
}

export default Navbar;